import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import { PrivacyPolicyTemplate } from '../../../shared'

const PrivacyPolicyPage = ({ data }) => {
  return (
    <Layout>
      <PrivacyPolicyTemplate data={data} />
    </Layout>
  )
}

export default PrivacyPolicyPage

export const privacyPageQuery = graphql`
  query PrivacyPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
    }
  }
`
